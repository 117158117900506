<template>
  <v-row class="match-height">
    <!-- Multiple Column -->
    <v-col cols="12">
      <app-card-code
        :title="`${$t('menu.configuration')} / ${$t('menu.markups')} / ${$t('menu.flights')}`"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                :loading="loading"
                @click="$router.push({ name: 'markups-list' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
          </v-row>
          <v-form class="multi-col-validation">
            <v-row>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="model.proveedor_id"
                  :items="proveedores"
                  item-text="name_comercial"
                  item-value="id"
                  :label="$t('lbl.proveedor')"
                  outlined
                  dense
                  hide-details
                  @change="findAll()"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <!--@change="changeAerolinea"-->
                <v-select
                  v-model="model.aerolinea_id"
                  :items="aerolineas"
                  item-text="name"
                  item-value="id"
                  :label="$t('lbl.operador')"
                  outlined
                  dense
                  hide-details
                  @change="findAll()"
                ></v-select>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <!--@change="changeVuelo"-->
                <v-autocomplete
                  ref="numero_de_vuelo"
                  v-model="model.numero_de_vuelo"
                  :items="numero_de_vuelos"
                  :search-input.sync="searchFlight"
                  hide-details
                  hide-selected
                  :label="$t('lbl.numeroVuelo')"
                  outlined
                  dense
                  @change="findAll()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.numeroVuelo') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-icon left>
                      {{ icons.mdiAirplaneTakeoff }}
                    </v-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="model.origen"
                  :items="itemsOrigen"
                  :search-input.sync="searchOrigen"
                  hide-details
                  hide-selected
                  :label="$t('lbl.origen')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  return-object
                  @change="findAll()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('booking.origenSearch') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-icon left>
                      {{ icons.mdiAirplaneTakeoff }}
                    </v-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="model.destino"
                  :items="itemsDestino"
                  :search-input.sync="searchDestino"
                  hide-details
                  hide-selected
                  :label="$t('lbl.destino')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  return-object
                  @change="findAll()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('booking.destinoSearch') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-icon left>
                      {{ icons.mdiAirplaneTakeoff }}
                    </v-icon>
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="model.clase"
                  :items="itemsClase"
                  :search-input.sync="searchClase"
                  hide-details
                  hide-selected
                  :label="$t('lbl.clase')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  return-object
                  @change="findAll()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.clase') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="model.tarifa"
                  :items="itemsTarifa"
                  :search-input.sync="searchTarifa"
                  hide-details
                  hide-selected
                  :label="$t('lbl.tarifa')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  return-object
                  @change="findAll()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('lbl.clase') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col
                cols="12"
                md="6"
              >
                <strong>{{ $t('lbl.frecuenciasFlight') }}</strong>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-radio-group v-model="radioGroup">
                      <v-row>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-radio
                            :label="$t('lbl.todosDay')"
                            @click="resetRadios"
                          ></v-radio>
                          <div class="d-block justify-start py-2">
                            <div class="d-flex justify-start">
                              <v-radio
                                :label="$t('lbl.semanaDay')"
                                class="pr-3"
                                @click="resetRadios"
                              ></v-radio>
                            </div>
                            <div class="pl-4">
                              <v-sheet class="py-2">
                                <v-slide-group
                                  v-model="modelSemanas"
                                  multiple
                                >
                                  <v-slide-item
                                    v-for="(item, index) in semanas"
                                    v-slot="{ active, toggle }"
                                    :key="index"
                                    class="px-1"
                                  >
                                    <span @click="editDates">
                                      <!--:disabled="!flightDetail.allowedFrecuencia.includes(index)"-->
                                      <v-btn
                                        :disabled="allowedFrecuenciaFlight.length > 0 && !allowedFrecuenciaFlight.includes(index)"
                                        class=""
                                        :input-value="active"
                                        active-class="primary white--text"
                                        depressed
                                        fab
                                        small
                                        @click="toggle"
                                      >
                                        {{ item }}
                                      </v-btn>
                                    </span>
                                  </v-slide-item>
                                </v-slide-group>
                              </v-sheet>
                            </div>
                          </div>
                        </v-col>
                        <v-col
                          cols="12"
                          md="12"
                        >
                          <v-radio
                            :label="$t('lbl.especifDay')"
                            class="py-2"
                            @click="resetRadios"
                          ></v-radio>
                          <template v-if="fromValidez !== null">
                            <v-row>
                              <v-col cols="8">
                                <v-date-picker
                                  v-model="dates"
                                  :first-day-of-week="0"
                                  :allowed-dates="allowedDates"
                                  no-title
                                  :show-current="false"
                                  full-width
                                  multiple
                                  locale="es"
                                  :min="fromValidez"
                                  :max="toValidez"
                                ></v-date-picker>
                              </v-col>
                            </v-row>
                          </template>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    sm="4"
                    md="4"
                  >
                    <strong>{{ $t('lbl.temporadas') }}</strong>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="2"
                    md="2"
                  >
                    <v-tooltip
                      top
                      class="text-right"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="adicionarTemporadaFlightMarkus()"
                        >
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.insert') }}</span>
                    </v-tooltip>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="12"
                    md="12"
                    class="pt-5"
                  >
                    <TemporadasFlight
                      v-for="(item, index) in $store.state.app.temporadasFlightMarkups"
                      :key="index"
                      :temporada="item"
                      :pos="index"
                      :desabilitar="false"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-form>
          <v-divider></v-divider>
          <v-form class="multi-col-validation px-5 py-5">
            <v-row>
              <v-col
                cols="12"
                md="1"
              >
                <v-btn
                  v-if="model.proveedor_id || model.aerolinea_id || model.numero_de_vuelo ||
                    model.origen || model.destino || model.clase || model.tarifa ||
                    dates.length > 0 || modelSemanas.length > 0 || radioGroup !== -1 ||
                    $store.state.app.temporadasFlightMarkups.length > 0"
                  class="mx-2"
                  fab
                  outlined
                  small
                  color="error"
                  @click="clearSearch()"
                >
                  <v-icon small>
                    {{ icons.mdiEraserVariant }}
                  </v-icon>
                </v-btn>
              </v-col>
              <v-col
                cols="12"
                md="9"
                class="text-center"
              >
                <v-btn
                  color="primary"
                  class="mb-4 me-3"
                  :loading="loading"
                  @click="save()"
                >
                  <span>{{ btnTitle }}</span>
                  <v-icon>{{ icons.mdiUpdate }}</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-uppercase text-center">
                    Markups
                  </th>
                  <th
                    width="25%"
                    class="text-uppercase text-center"
                  >
                    {{ $t('lbl.adulto') }}
                  </th>
                  <th
                    width="25%"
                    class="text-uppercase text-center"
                  >
                    {{ $t('lbl.menor') }}
                  </th>
                  <th
                    width="25%"
                    class="text-uppercase text-center"
                  >
                    {{ $t('lbl.infat') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(modelM, i) in $store.state.app.markupsFlightMarkups"
                  :key="i"
                >
                  <td class="text-center">
                    {{ modelM.name_tipo_afiliado }}
                  </td>
                  <td class="text-left">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="modelM.valorAdult"
                          :disabled="modelM.pcAdult !== null && modelM.pcAdult !== ''"
                          prefix="$"
                          outlined
                          dense
                          hide-details
                          min="0"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="modelM.pcAdult"
                          :disabled="modelM.valorAdult !== null && modelM.valorAdult !== ''"
                          outlined
                          dense
                          min="0"
                          type="number"
                          hide-details
                          suffix="%"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </td>
                  <td class="text-left">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="modelM.valorChild"
                          :disabled="modelM.pcChild !== null && modelM.pcChild !== ''"
                          prefix="$"
                          outlined
                          dense
                          hide-details
                          min="0"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="modelM.pcChild"
                          :disabled="modelM.valorChild !== null && modelM.valorChild !== ''"
                          outlined
                          dense
                          min="0"
                          type="number"
                          hide-details
                          suffix="%"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </td>
                  <td class="text-left">
                    <v-row>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="modelM.valorInf"
                          :disabled="modelM.pcInf !== null && modelM.pcInf !== ''"
                          prefix="$"
                          outlined
                          dense
                          hide-details
                          min="0"
                          type="number"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        sm="6"
                        md="6"
                      >
                        <v-text-field
                          v-model="modelM.pcInf"
                          :disabled="modelM.valorInf !== null && modelM.valorInf !== ''"
                          outlined
                          dense
                          min="0"
                          type="number"
                          hide-details
                          suffix="%"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiAirplaneTakeoff,
  mdiEraserVariant,
} from '@mdi/js'

// eslint-disable-next-line import/extensions
import TemporadasFlight from '../utils/TemporadasFlight'

export default {
  components: {
    AppCardCode,
    TemporadasFlight,
  },
  data() {
    return {
      isLoading: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiAirplaneTakeoff,
        mdiEraserVariant,
      },
      model: {},
      aerolineas: [],
      proveedores: [],
      numero_de_vuelos: [],
      clases: [],
      tarifas: [],
      searchFlight: null,
      itemsOrigen: [],
      id_origen_contenidos: [],
      searchOrigen: null,
      itemsDestino: [],
      id_destino_contenidos: [],
      searchDestino: null,
      itemsClase: [],
      searchClase: null,
      itemsTarifa: [],
      searchTarifa: null,

      markups: [],
      typeProduct: {},
      typeAfiliados: [],
      loading: false,
      editando: false,

      radioGroup: -1,
      semanas: [],
      modelSemanas: [],
      dates: [],
      allowedDatesFlight: [],
      fromValidez: null,
      toValidez: null,
      flightsList: [],
      allowedFrecuenciaFlight: [],

      permisos: localStorage.getItem('permisos_auth'),
      is_nivel_propietario: false,
      user: {},
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('markups-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
  },
  watch: {
    searchOrigen(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOrigen(val.toLowerCase())
      } else {
        this.itemsOrigen = []
      }
    },
    searchDestino(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestino(val.toLowerCase())
      } else {
        this.itemsDestino = []
      }
    },
    searchFlight(val) {
      this.filterFlights(val.toUpperCase())

      /* if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterFlights(val.toUpperCase())
      } else {
        this.flightsSelect = []
      } */
    },
    searchClase(val) {
      this.filterClase(val.toUpperCase())

      /* if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterClase(val.toUpperCase())
      } else {
        this.itemsClase = []
      } */
    },
    searchTarifa(val) {
      this.filterTarifa(val.toUpperCase())

      /* if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterTarifa(val.toUpperCase())
      } else {
        this.itemsTarifa = []
      } */
    },
  },
  created() {
    this.resetAll()
  },
  methods: {
    ...mapMutations(['adicionarTemporadaFlightMarkus', 'initMarkupsFlight', 'updateTemporadaFlightMarkus', 'updateMarkusFlight']),
    filterOrigen(v) {
      this.itemsOrigen = []
      if (v === '') {
        this.itemsOrigen = []
      } else {
        const json = {
          search: v,
          id_contenidos: this.id_origen_contenidos,
        }
        this.axios
          .post(
            'airports-names-filter',
            json,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsOrigen.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })
          })
      }
    },
    filterDestino(v) {
      this.itemsDestino = []
      if (v === '') {
        this.itemsDestino = []
      } else {
        const json = {
          search: v,
          id_contenidos: this.id_destino_contenidos,
        }
        this.axios
          .post(
            'airports-names-filter',
            json,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsDestino.push({ id: element.id, name: element.nameComodin, iataComodin: element.iataComodin })
            })
          })
      }
    },
    filterFlights(v) {
      this.flightsSelect = []
      if (v === '') {
        this.flightsSelect = []
      } else {
        this.flightsSelect = this.numero_de_vuelos.filter(e => e)
      }
    },
    filterClase(v) {
      this.itemsClase = this.clases.filter(e => e)

      this.itemsClase = []
      if (v === '') {
        this.itemsClase = []
      } else {
        this.itemsClase = this.clases.filter(e => e)
      }
    },
    filterTarifa(v) {
      this.itemsTarifa = []
      if (v === '') {
        this.itemsTarifa = []
      } else {
        this.itemsTarifa = this.tarifas.filter(e => e)
      }
    },
    resetAll() {
      this.profile()
      this.getProveedores()
      this.getAerolineas()
      this.getClases()
      this.getTarifas()
      this.getTypeProduct()
      this.getTypeAfiliado()
      this.getFlights()
      setTimeout(() => {
        this.getItem()
      }, 300)

      this.semanas = []
      this.semanas.push('D')
      this.semanas.push('L')
      this.semanas.push('M')
      this.semanas.push('X')
      this.semanas.push('J')
      this.semanas.push('V')
      this.semanas.push('S')
      this.resetDatesAll()
    },
    resetDatesAll() {
      this.allowedFrecuenciaFlight = []
      this.fromValidez = this.$moment().format('YYYY-MM-DD')
      this.toValidez = this.$moment().add('years', 1).format('YYYY-MM-DD')
      let dateca = this.fromValidez
      while (dateca <= this.toValidez) {
        this.allowedDatesFlight.push(dateca)
        dateca = this.$moment(dateca).add('days', 1).format('YYYY-MM-DD')
      }
    },

    profile() {
      this.axios
        .get(`${process.env.VUE_APP_API_URL}profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // eslint-disable-next-line no-empty
          if (response.data.success === false) {
          } else if (response.data.data.nivel === -1) {
            this.is_nivel_propietario = true
          }
          this.user = response.data.data
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    getItem() {
      if (localStorage.getItem('markups-id') !== null) {
        this.isLoading = true
        this.editando = true

        this.axios
          .get(`markups/${localStorage.getItem('markups-id')}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.success === true) {
              this.model = res.data.data.data.data_specific
              if (this.model.numero_de_vuelo) {
                this.numero_de_vuelos.push(this.model.numero_de_vuelo)

                this.changeVuelo()
              }
              if (this.model.origen) {
                this.itemsOrigen.push(this.model.origen)
              }
              if (this.model.destino) {
                this.itemsDestino.push(this.model.destino)
              }
              if (this.model.tarifa) {
                this.itemsTarifa.push(this.model.tarifa)
              }
              if (this.model.clase) {
                this.itemsClase.push(this.model.clase)
              }
              if (this.model.data_frecuencia) {
                this.radioGroup = this.model.data_frecuencia.tab
                this.modelSemanas = this.model.data_frecuencia.modelSemanas
                this.dates = this.model.data_frecuencia.dates
              }
              if (res.data.data.data.temporadas.length > 0) {
                this.updateTemporadaFlightMarkus(res.data.data.data.temporadas)
              } else {
                this.updateTemporadaFlightMarkus([{
                  fromValidez: '',
                  toValidez: '',
                }])
              }
              if (res.data.data.data.data_markups) {
                this.updateMarkusFlight(res.data.data.data.data_markups)
              } else {
                this.updateMarkusFlight([])
              }
            } else {
              const typesAfiliados = []
              this.typeAfiliados.forEach(element => {
                typesAfiliados.push({ id: element.id, name: element.name })
              })
              this.initMarkupsFlight(typesAfiliados)
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.updateTemporadaFlightMarkus([{
          fromValidez: '',
          toValidez: '',
        }])
        this.updateMarkusFlight([])
        const typesAfiliados = []
        this.typeAfiliados.forEach(element => {
          typesAfiliados.push({ id: element.id, name: element.name })
        })
        this.initMarkupsFlight(typesAfiliados)
      }
    },
    getTypeProduct() {
      this.axios
        .get(`type_product/${localStorage.getItem('markups-id')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.typeProduct = res.data.data.data
        })
    },
    getTypeAfiliado() {
      this.axios
        .get(`tipo_afiliado?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.typeAfiliados = res.data.data
        })
    },
    getFlights() {
      this.axios
        .get('contrate_flights/list', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.flightsList = res.data.data
          this.flightsList.forEach(element => {
            if (element.tiene_contrato) {
              this.numero_de_vuelos.push(element.numero_de_vuelo)
            }
          })
        })
    },
    save() {
      this.loading = true
      const jsonF = {
        tab: this.radioGroup,
        modelSemanas: this.modelSemanas,
        dates: this.dates,
      }
      this.model.data_frecuencia = jsonF
      const json = {
        slug: 'vuelos',
        perfil_slug: localStorage.getItem('perfil'),
        temporadas: this.$store.state.app.temporadasFlightMarkups,
        data_specific: this.model,
        data_markups: this.$store.state.app.markupsFlightMarkups,
      }

      // console.log(json)
      if (this.$store.state.app.temporadasFlightMarkups.length > 0) {
        if (localStorage.getItem('markups-id') !== null) {
          this.axios
            .put(`markups/${localStorage.getItem('markups-id')}`, json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                localStorage.removeItem('markups-id')
                localStorage.removeItem('markups-slug')
                this.$router.push({ name: 'markups-list' })
              }
            })
            .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .post('markups', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === false) {
                // this.$toast.error(this.$t('msg.noAutorice'))
              } else {
                localStorage.removeItem('markups-id')
                localStorage.removeItem('markups-slug')
                this.$router.push({ name: 'markups-list' })
              }
            })
            .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.emptySeason'))
      }
    },
    getAerolineas() {
      this.axios
        .get(`nom_aerolines?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.aerolineas = res.data.data
        })
    },
    getProveedores() {
      this.axios
        .post('proveedors/byproduct', { slug: 'vuelos' }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.proveedores = []
          this.proveedores.push({ id: 0, name_comercial: 'Todos' })
          res.data.data.forEach(element => {
            this.proveedores.push({ id: element.id, name_comercial: element.name_comercial })
          })
        })
    },
    getClases() {
      this.axios
        .get('nom_clase_flights?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.clases = res.data.data
          this.itemsClase = this.clases
        })
    },
    getTarifas() {
      this.axios
        .get('nom_tarifa_flights?per_page=1000', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.tarifas = res.data.data
          this.itemsTarifa = this.tarifas
        })
    },

    changeAerolinea() {
      if (this.model.aerolinea_id) {
        const air = this.aerolineas.filter(a => a.id === this.model.aerolinea_id)[0]

        // this.model.numero_de_vuelo = `${air.slug} `
        // this.$refs.numero_de_vuelo.focus()

        // RESERT VALUES
        this.model.numero_de_vuelo = null
        this.numero_de_vuelos = air.numero_vuelos_validos
        this.itemsClase = this.clases
        this.itemsTarifa = this.tarifas
        this.resetModel()
      } else {
        this.flights = []
      }
    },
    changeVuelo() {
      this.allowedFrecuenciaFlight = []
      // eslint-disable-next-line camelcase
      const flight_id = this.flightsList.filter(f => f.numero_de_vuelo === this.model.numero_de_vuelo)[0]
      // eslint-disable-next-line camelcase
      if (this.model.numero_de_vuelo && flight_id) {
        this.axios
          // eslint-disable-next-line camelcase
          .get(`flight/${flight_id.id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(resFlig => {
            this.flightDetail = resFlig.data.data.data

            // console.log(this.flightDetail)

            this.toValidez = this.flightDetail.toValidez
            this.allowedFrecuenciaFlight = this.flightDetail.allowedFrecuencia
            this.allowedDatesFlight = this.flightDetail.allowedDates

            this.itemsClase = this.flightDetail.clases
            this.itemsTarifa = this.flightDetail.tarifas

            // this.airline = this.flightDetail.aerolinea
          })
      } else {
        this.resetDatesAll()
        this.resetModel()
      }
    },

    resetModel() {
      this.radioGroup = -1
      this.flightDetail = null
      this.airline = null
      this.dates = []
      this.modelSemanas = []

      this.model.tarifa = null
      this.model.clase = null
    },

    // eslint-disable-next-line consistent-return
    allowedDates(val) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.allowedDatesFlight.length; i++) {
        if (this.allowedDatesFlight[i] === val) {
          return val
        }
      }
    },

    resetRadios() {
      if (this.radioGroup === 0) {
        this.dates = this.allowedDatesFlight
        this.modelSemanas = this.allowedFrecuenciaFlight
      } else if (this.radioGroup === 1) {
        this.dates = []
        this.modelSemanas = []
      } else if (this.radioGroup === 2) {
        this.modelSemanas = []
        this.dates = []
      }
    },
    editDates() {
      this.dates = []
      this.modelSemanas.forEach(elementSemana => {
        this.allowedDatesFlight.forEach(elementFecha => {
          if (elementSemana === new Date(elementFecha).getDay() + 1) {
            this.dates.push(elementFecha)

            // para w pinchara el domin go
          } else if (elementSemana === 0) {
            if (new Date(elementFecha).getDay() === 6) {
              this.dates.push(elementFecha)
            }
          }
        })
      })
    },
    clearSearch() {
      this.model = {}
      this.resetAll()
      this.radioGroup = -1
      this.flightDetail = null
      this.airline = null
      this.dates = []
      this.modelSemanas = []
    },

    // METODOS DURO PARA FILTRAR ALL
    findAll() {
      const json = {
        proveedor_id: this.model.proveedor_id,
        aerolinea_id: this.model.aerolinea_id,
        numero_de_vuelo: this.model.numero_de_vuelo,
        origen: this.model.origen,
        destino: this.model.destino,
        clase: this.model.clase,
        tarifa: this.model.tarifa,
        dates: this.dates,
        modelSemanas: this.modelSemanas,
        id_origen_contenidos: this.id_origen_contenidos,
        id_destino_contenidos: this.id_destino_contenidos,
      }

      // console.log(json)

      this.axios
        .post('markups-search-find-all', json, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          // console.log(response.data.data)

          this.aerolineas = response.data.data.aerolineas
          this.numero_de_vuelos = response.data.data.numero_de_vuelos
          this.id_origen_contenidos = response.data.data.id_origen_contenidos
          this.id_destino_contenidos = response.data.data.id_destino_contenidos
          this.itemsClase = response.data.data.clases
          this.itemsTarifa = response.data.data.tarifas

          this.allowedFrecuenciaFlight = response.data.data.modelSemanas
          this.fromValidez = response.data.data.fromValidez
          this.toValidez = response.data.data.toValidez
          this.allowedDatesFlight = response.data.data.allowedDates

          /* if (response.data.success === false) {
            this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            localStorage.removeItem('markups-id')
            localStorage.removeItem('markups-slug')
            this.$router.push({ name: 'markups-list' })
          } */
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
  },
}
</script>
